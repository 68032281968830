<template>
    <div class="bg-white my-4 vx-row w-full">
        <div class="pt-10 px-8">
            <h3>{{ $t("apps.configurator.home-layout.title") }}</h3>
            <span>{{ $t("apps.configurator.home-layout.subtitle") }}</span>
        </div>
        <div class="pt-10 px-8">
            <b-tabs class="m-0">
                <b-tab v-for="layoutType in types" :key="layoutType">
                    <template #title>
                        <span class="font-semibold">{{ $t(`apps.configurator.home-layout.type-${layoutType}`) }}</span>
                    </template>
                    <div v-if="homeLayoutData[layoutType].data !== null" class="px-8">
                        <div class="mb-5">
                            <DynamicForm :isReadOnly="isReadOnly" :value="homeLayoutData[layoutType].formData" @input="getDinamicInputs(layoutType, $event)"
                                @check-validation="validateTypeErrors(layoutType, $event)" />
                            <b-alert :show="homeLayoutData.home.hasErrors" variant="danger" class="my-5 rounded-lg">{{
                                $t("apps.configurator.home-layout.errors") }}</b-alert>
                        </div>
                        <div class="action-item-button-container buttons pb-5" v-if="!isReadOnly">
                            <vs-button color="primary" type="border rounded-lg mr-3" @click.stop="cancelHomeLayout(layoutType)">
                                {{ $t("apps.configurator.home-layout.cancel") }}
                            </vs-button>
                            <vs-button class="xs:w-full sm:w-auto rounded-lg" @click.stop="saveHomeLayout(layoutType)">
                                {{ $t("apps.configurator.home-layout.save") }}
                            </vs-button>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfiguratorHomeLayoutService from '@/modules/Apps/Services/Configurator/ConfiguratorHomeLayoutService'
import ConfiguratorHomeLayoutTemplateService from '@/modules/Apps/Services/Configurator/ConfiguratorHomeLayoutTemplateService'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import DynamicForm from "@/modules/Shared/Components/form/DynamicForm";
import { v4 as uuidv4 } from 'uuid';
import ConfiguratorVersionService from "@/modules/Apps/Services/Configurator/ConfiguratorVersionService";
import store from "@/modules/Shared/Store/store";

export default {
    name: 'home-layout-list',
    components: {
        DynamicForm
    },
    computed: {
        ...mapGetters(['applicationUuid']),
        isReadOnly() {
            return this.$store.state.auth.drm['admin'].length === 0
        }
    },
    data() {
        return {
            types: ['home', 'selector', 'profile'],
            versionUuid: this.$route.params.versionUuid,
            layoutTemplate: null,
            homeLayoutData: {
                home: {
                    data: null,
                    uuid: null,
                    formData: null,
                    actionType: null,
                    hasErrors: false
                },
                selector: {
                    data: null,
                    uuid: null,
                    formData: null,
                    actionType: null,
                    hasErrors: false
                },
                profile: {
                    data: null,
                    uuid: null,
                    formData: null,
                    actionType: null,
                    hasErrors: false
                }
            }
        }
    },
    async beforeRouteEnter (to, from, next) {
        const version = await ConfiguratorVersionService.getVersion(to.params.versionUuid);
        await store.dispatch('setRouteElement', version)
        next()
    },
    methods: {
        validateTypeErrors(type, isValid) {
            const layoutData = this.homeLayoutData[type];
            if (layoutData) {
                layoutData.hasErrors = !isValid;
            }
        },
        async prepareHomeLayoutTemplateData() {
            // Its only 1 home layout per version
            let response = await ConfiguratorHomeLayoutTemplateService.getAllTemplates()
            this.layoutTemplate = response.data[0]
        },

        async prepareHomeLayoutData() {
            let params = {
                page: 1,
                filters: [{ field: "version_uuid", value: this.versionUuid }]
            }

            let response = await ConfiguratorHomeLayoutService.getHomeLayoutList(PaginationService.parseParameters(params))

            this.types.forEach((layoutType) => this.setHomeLayoutDataforCreate(layoutType))

            if (response.data.length > 0) {
                response.data.forEach((layout) => this.setHomeLayoutDataForType(layout.type, layout))
            }
        },
        setHomeLayoutDataForType(type, homeLayoutData) {
            const layoutData = this.homeLayoutData[type];
            if (layoutData) {
                layoutData.data = homeLayoutData.values;
                layoutData.uuid = homeLayoutData.uuid;
                layoutData.actionType = "update";

                layoutData.formData = {
                    template: this.layoutTemplate.form,
                    values: layoutData.data
                };
            }
        },
        setHomeLayoutDataforCreate(type) {
            const layoutData = this.homeLayoutData[type];
            if (layoutData) {
                layoutData.actionType = "create";
                layoutData.data = this.layoutTemplate.form.reduce((obj, item) => {
                    obj[item.key] = "";
                    return obj;
                }, {});
                layoutData.uuid = uuidv4();

                layoutData.formData = {
                    template: this.layoutTemplate.form,
                    values: layoutData.data
                };
            }
        },
        getDinamicInputs(type, updatedData) {
            const layoutData = this.homeLayoutData[type];
            if (layoutData) {
                layoutData.formData = updatedData;
            }
        },
        async saveHomeLayout(type) {
            const layoutData = this.homeLayoutData[type];
            if (layoutData && !layoutData.hasErrors) {
                this.createOrUpdateHomeLayout(layoutData.actionType, layoutData.uuid, layoutData.formData.values, type);
            }
        },
        async createOrUpdateHomeLayout(action, homeLayoutUuid, dynamicFormDataValues, type) {
            this.$vs.loading()
            let response
            if (action === "create") {
                response = await ConfiguratorHomeLayoutService.createHomeLayout(homeLayoutUuid, { version_uuid: this.versionUuid, values: JSON.stringify(dynamicFormDataValues), type: type })
            } else {
                response = await ConfiguratorHomeLayoutService.updateHomeLayout(homeLayoutUuid, { version_uuid: this.versionUuid, values: JSON.stringify(dynamicFormDataValues), type: type })
            }
            if (response.status_code == 201 || response.status_code == 200) {
                this.$vs.notify({ text: this.$t("apps.configurator.home-layout.saved.ok"), color: 'success', position: 'top-right' })
            } else {
                this.$vs.notify({ text: this.$t("apps.configurator.home-layout.saved.ko"), color: 'danger', position: 'top-right' })
            }
            this.$vs.loading.close();
        },
        cancelHomeLayout(type) {
            const layoutData = this.homeLayoutData[type];
            if (layoutData) {
                Object.keys(layoutData.formData.values).forEach((value) => layoutData.formData.values[value] = "");
            }
        }
    },
    async created() {
        await this.prepareHomeLayoutTemplateData()
        await this.prepareHomeLayoutData()
    }
}
</script>
